// Mixpanel //
import mixpanelLib from 'mixpanel-browser';

document.addEventListener("turbo:load", () => {
  if ( gon.mixpanel_enabled ){
    window.mixpanel = mixpanelLib;
    mixpanel.init(gon.mixpanel_tracking_id, { "api_host": "https://api-eu.mixpanel.com", "ip": false, batch_requests: true }, "");
    mixpanel.identify(gon.tracking.userId);
    mixpanel.people.set(gon.tracking.userProperties);

    gon.tracking.events.forEach((event) => {
      event.properties = { ...event.properties, ...{ time: Date.now() / 1000 } };
      mixpanel.track(event.name, event.properties);
    });
  } else {
    const noop = () => undefined;

    window.mixpanel = {
      init: noop,
      track: noop,
    };
  }
});
