import 'core-js/stable';

// eslint-disable-next-line @typescript-eslint/no-var-requires
require('@rails/ujs').start();
require('@hotwired/turbo-rails');

// eslint-disable-next-line import/extensions
require('@webcomponents/webcomponentsjs/webcomponents-bundle.js');
require('../bootStimulus');
require('../tracking');

require('../stylesheets/booking_system.scss');
require('../stylesheets/custom.scss');

const images = require.context('../images', true);
